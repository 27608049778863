@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  /* margin: 0; */
  font-family: 'Lato', sans-serif;
}

.hero {
  padding-top: 0;
  /* background: url(./images/hero-bg.svg) top center no-repeat; */
  background-size: cover;
  background-position: 1;
  background-repeat: no-repeat;
  background: linear-gradient(rgba(2,2,2,.1),rgba(0,0,0,.2)),url(../src/images/cardrivingschool.png)  ; 
}



/* .footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
} */

/* .cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(../src/images/torontonight1.svg) fixed center;
} */


/* .cta {
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)) fixed center center,
    url(../src/images/torontonight1.svg)  ;
   /* Prevent background image from repeating */
